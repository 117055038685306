<script setup lang="ts">
import MyValueLogo from '~/assets/images/myvalue_logo_grayscale.svg';
const auth = useAuth();
</script>

<template>
  <h2 class="text-h6 text-center">
    <slot />
    <template v-if="auth.isPartner">
      &nbsp;
      <span style="margin-left: -0.6rem">{{ $t('auth.withAccount') }}</span>
      <img :src="MyValueLogo" alt="MyValue" class="logo" />
    </template>
  </h2>
</template>

<style scoped lang="scss">
.text-center {
  color: #4a4f54;
  font-weight: 700;
}
.logo {
  width: 5.7rem;
  height: 100%;
  margin-left: 0.5rem;
  transform: translateY(0.05rem);
}
</style>
